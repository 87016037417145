import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { get_branch, get_course, get_grade, get_country, get_state, subject_all, insertId, get_term } from '../../../apis/Common';
import { free_assesment_form } from "../../../apis/Signup"
import { FreevalidateStep } from './FreeAssesValidation';

const LoginFreeAssesment = () => {
    //state for steps
    const [step, setstep] = useState(1);
    const [grades, setGrades] = useState([]);
    const [terms, setTerms] = useState([]);
    const [course, setCourse] = useState([]);
    const [branch, setBranch] = useState([]);
    const [country, setCountry] = useState([]);
    const [subject, setSubject] = useState([]);
    const [state, setState] = useState([]);
    const [id, setId] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        grade: '',
        email: '',
        mobile: '',
        date_of_birth: '',
        school: '',
        subject_id: '',
        password: '',
        term_id:''
    });

    const [errors, setErrors] = useState({
        name: '',
        grade: '',
        email: '',
        mobile: '',
        date_of_birth: '',
        school: '',
        subject_id: '',
        password: '',
        // term_id:''
    });

    const navigate = useNavigate();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '',
        });
    };
    const previousStep = () => {
        setstep(step - 1);
    }
    // function for going to next step by increasing step state by 1
    const nextStep = () => {
        const currentStep = step;
        const validationErrors = FreevalidateStep(currentStep, formData);
        if (Object.values(validationErrors).every((error) => !error)) {
            setstep(step + 1);
        } else {
            setErrors(validationErrors);
        }
    };
    const onSubmit = async (e) => {
        e.preventDefault();
        const res = await free_assesment_form(formData);
        if (res?.status) {
            return navigate('/login')
        }
        nextStep();
        console.log('yes', formData);
    }


    useEffect(() => {
        async function get_data() {
            const subject = await subject_all();
            const grade = await get_grade();
            const course = await get_course();
            const branch = await get_branch();
            const country = await get_country();
            const term = await get_term();
            const id = await insertId();
            setTerms(term?.data)
            setSubject(subject?.data);
            setGrades(grade?.data);
            setCourse(course?.data);
            setBranch(branch?.data);
            setCountry(country?.data);
            setId(`STUD${id?.data?.id ?? 1}`);
        }
        get_data();
    }, []);

    //get state
    useEffect(() => {
        async function get_data() {
            const state = await get_state();
            setState(state?.data);
        }
        get_data();
    }, [localStorage.getItem('countryId')]);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 8);

    const maxDateStr = maxDate.toISOString().split('T')[0];
    return (
        <div className="row">
            <div className="col-12">
                <div className="member_title">
                    <h2>Free Assesment</h2>
                    <h4>Already Member? <Link to="/login">login here</Link></h4>
                </div>

                <div className="tab_out">
                    <nav>
                        <div className="nav nav-tabs join_tab" id="nav-tab" role="tablist">
                            <button onClick={() => setstep(1)} className={`nav-link ${step === 1 ? 'active' : ''}`} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Step 1</button>
                            <button onClick={() => setstep(2)} className={`nav-link ${step === 2 ? 'active' : ''}`} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Step 2</button>
                        </div>
                    </nav>
                    <form onSubmit={(e) => onSubmit(e)}>
                        {/* javascript switch case to show different form in each step */}
                        <div className="tab_out_cont">
                            <div className="tab-content" id="nav-tabContent">
                                {(() => {
                                    switch (step) {
                                        case 1:
                                            return (
                                                <div className={`tab-pane fade ${step === 1 ? 'show active' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                    <div className="info_cont">
                                                        <h4>Students Information</h4>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Name</label>
                                                                <input type="text" name="name" value={formData.name}
                                                                    onChange={(e) => handleChange(e)}
                                                                    className="form-control" id="exampleFormControlInput1" placeholder="Name" />
                                                                {errors.name && <p className="error">{errors.name}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Grade</label>
                                                                <select className="form-select" name="grade" onChange={(e) => (handleChange(e))}
                                                                    value={formData.grade} >
                                                                    <option value='grade'>Choose Grade</option>
                                                                    {grades && grades.map((elem) => {
                                                                        return (
                                                                            <option value={elem.id}>grade{elem.name}</option>)
                                                                    })}
                                                                </select>
                                                                {errors.grade && <p className="error">{errors.grade}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.55769 13.3097H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.55769 17.1962H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.0438 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.96564 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Term</label>
                                                                <select className="form-select" name="term_id" onChange={(e) => handleChange(e)}
                                                                    value={formData.term_id} >
                                                                    <option value='term'>Choose Term</option>
                                                                    {terms && terms.map((elem) => {
                                                                        return (
                                                                            <option value={elem.id}>{elem.name}</option>)
                                                                    })}
                                                                </select>
                                                                {/* {errors.term_id && <p className="error">{errors.term_id}</p>} */}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.55769 13.3097H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.55769 17.1962H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.0438 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.96564 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Email</label>
                                                                <input type="text" className="form-control"
                                                                    name="email" onChange={(e) => handleChange(e)}
                                                                    value={formData.email}
                                                                    id="exampleFormControlInput1" placeholder="Email" />
                                                                {errors.email && <p className="error">{errors.email}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.42857"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Phone No.</label>
                                                                <input onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }} className="form-control"
                                                                    name="mobile" onChange={(e) => handleChange(e)}
                                                                    value={formData.mobile}
                                                                    id="exampleFormControlInput1" placeholder="Phone No." />
                                                                {errors.mobile && <p className="error">{errors.mobile}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Date of birth</label>
                                                                <input type="date" className="form-control"
                                                                    name="date_of_birth" onChange={(e) => handleChange(e)}
                                                                    value={formData.date_of_birth} id="exampleFormControlInput1" placeholder="Date of birth" max={maxDateStr} />
                                                                {errors.date_of_birth && <p className="error">{errors.date_of_birth}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.55769 13.3097H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.55769 17.1962H7.56695" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M16.0438 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path d="M7.96564 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                        <path
                                                                            fill-rule="evenodd"
                                                                            clip-rule="evenodd"
                                                                            d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">School Name</label>
                                                                <input type="text" className="form-control"
                                                                    name="school" onChange={(e) => handleChange(e)}
                                                                    value={formData.school} id="exampleFormControlInput1" placeholder="School Name" />
                                                                {errors.school && <p className="error">{errors.school}</p>}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Branch</label>
                                                                <select className="form-select" name="branch" value={formData.branch} onChange={(e) => handleChange(e)}>
                                                                    <option value=''>Choose Branch</option>
                                                                    {branch && branch.map((elem) => {
                                                                        return (
                                                                            <option value={elem.id}>{elem.branch_name}</option>)
                                                                    })}
                                                                </select>
                                                                {/* {errors.branch && <p className="error">{errors.branch}</p>} */}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <div className="mb-3 info_field">
                                                                <label for="exampleFormControlInput1" className="form-label">Subject</label>
                                                                <select className="form-select" name="subject_id"
                                                                    value={formData.subject_id} onChange={(e) => handleChange(e)} >
                                                                    <option value="">Choose Subject</option>
                                                                    {subject?.map((elem) => {
                                                                        return (<option value={elem.id} >{elem.subject}</option>)
                                                                    })}
                                                                </select>
                                                                {/* {errors.subject_id && <p className="error">{errors.subject_id}</p>} */}
                                                                <div className="work_icon">
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                            stroke="#2D2926"
                                                                            stroke-width="1.5"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="next_btn">
                                                            <button type="button" onClick={() => nextStep()}>NEXT</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            break;
                                        case 2:
                                            return (
                                                <div className={`tab-pane fade ${step === 2 ? 'show active' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                                    <div className="tab-content" id="nav-tabContent">
                                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                                            <div className="info_cont">
                                                                {/* <h4>Students Information</h4> */}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Email</label>
                                                                        <input type="text" className="form-control"
                                                                            name="email" onChange={(e) => handleChange(e)}
                                                                            value={formData.email}
                                                                            id="exampleFormControlInput1" placeholder="Email" />
                                                                        {errors.email && <p className="error">{errors.email}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.42857"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Password</label>
                                                                        <input type="password" className="form-control"
                                                                            name="password" onChange={(e) => handleChange(e)}
                                                                            value={formData.password}
                                                                            id="exampleFormControlInput1" placeholder="Password" />
                                                                        {errors.password && <p className="error">{errors.password}</p>}
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-lg-3">
                                                                    <div className="mb-3 info_field">
                                                                        <label for="exampleFormControlInput1" className="form-label">Student Id</label>
                                                                        <input  className="form-control"
                                                                         key={id}   value={id}
                                                                            id="exampleFormControlInput1"   disabled/>
                                                                        <div className="work_icon">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M16.4232 9.4478V7.3008C16.4232 4.7878 14.3852 2.7498 11.8722 2.7498C9.35925 2.7388 7.31325 4.7668 7.30225 7.2808V7.3008V9.4478"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path
                                                                                    fill-rule="evenodd"
                                                                                    clip-rule="evenodd"
                                                                                    d="M15.683 21.2497H8.042C5.948 21.2497 4.25 19.5527 4.25 17.4577V13.1687C4.25 11.0737 5.948 9.37671 8.042 9.37671H15.683C17.777 9.37671 19.475 11.0737 19.475 13.1687V17.4577C19.475 19.5527 17.777 21.2497 15.683 21.2497Z"
                                                                                    stroke="#2D2926"
                                                                                    stroke-width="1.5"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                />
                                                                                <path d="M11.8628 14.2026V16.4236" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="next_btn ">
                                                                    <a className='prev_btn' href="javascript:;" onClick={() => previousStep()}>PREVIOUS</a>
                                                                    <button type="submit">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        default:
                                            return null
                                    }
                                })()
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="member_cont">
                            <h4>Hybrid Learning</h4>
                            <p>Hybrid learning implements synchronous lessons taught simultaneously in-person and online.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="member_cont">
                            <h4>Top Materials</h4>
                            <p>All materials are designed and prepared by a team of highly qualified and experienced educators.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="member_cont">
                            <h4>Best Teachers</h4>
                            <p>Qualified and experienced teachers ensure students enjoy their studies & achieve their ambitions</p>
                        </div>
                    </div>
                </div>
                <div className="group_img">
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csgroup.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/testbank.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csinstitute.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csschool.png" alt="csgroup" />
                    </div>
                    <div className="group_inner_img">
                        <img className="w-100" src="./images/csfranchise.png" alt="csgroup" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginFreeAssesment;


