import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import user from '../../assets/Images/user.png'
import logo from '../../assets/Images/logo.png'
export default function Header({toggleClass}) {
	const img=localStorage.getItem('profileImg')
	const userImage =localStorage.getItem('userImage')
	return (
		<div className="myheader">
			<div className="top_bar">
				<div className="top_bar_inner">
					<h4><i className="fa-solid fa-phone"></i>  +61 2 8883 0055    <span className="h_line">|</span>      <i className="fa-solid fa-location-dot"></i>    Unit 121, 14 Lexington Dr. Bella vista NSW 2153</h4>
				</div>
				<div className="top_bar_inner">
					<h4> <span className="h_line"></span> NSW </h4>
					{/* <h4>Enroll Now     <span className="h_line">|</span>      VIC   <span className="h_line">|</span>   QLD   <span className="h_line">|</span>   SA</h4> */}

				</div>
			</div>
			<nav className="sb-topnav navbar navbar-expand">
				<Link className="navbar-brand ps-3" to="/" title="CS ONLINE SCHOOL"><img src={logo} alt='logo'/></Link>
				<button onClick={() => toggleClass() } className="btn btn-link btn-sm order-1 order-lg-0  me-lg-0" id="sidebarToggle" to="#!">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M13 16H3C2.73478 16 2.48043 16.1054 2.29289 16.2929C2.10536 16.4804 2 16.7348 2 17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H13C13.2652 18 13.5196 17.8946 13.7071 17.7071C13.8946 17.5196 14 17.2652 14 17C14 16.7348 13.8946 16.4804 13.7071 16.2929C13.5196 16.1054 13.2652 16 13 16ZM3 8H21C21.2652 8 21.5196 7.89464 21.7071 7.70711C21.8946 7.51957 22 7.26522 22 7C22 6.73478 21.8946 6.48043 21.7071 6.29289C21.5196 6.10536 21.2652 6 21 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7C2 7.26522 2.10536 7.51957 2.29289 7.70711C2.48043 7.89464 2.73478 8 3 8ZM21 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8946 2.73478 13 3 13H21C21.2652 13 21.5196 12.8946 21.7071 12.7071C21.8946 12.5196 22 12.2652 22 12C22 11.7348 21.8946 11.4804 21.7071 11.2929C21.5196 11.1054 21.2652 11 21 11Z" fill="black" />
					</svg>
				</button>
				<ul className="navbar-nav ms-auto">
					{/* <li className="nav-item dropdown notification">
						<span></span>
						<Link className="nav-link dropdown-toggle" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M16.625 6.125H14V5.25C14 4.32174 13.6313 3.4315 12.9749 2.77513C12.3185 2.11875 11.4283 1.75 10.5 1.75C9.57174 1.75 8.6815 2.11875 8.02513 2.77513C7.36875 3.4315 7 4.32174 7 5.25V6.125H4.375C4.14294 6.125 3.92038 6.21719 3.75628 6.38128C3.59219 6.54538 3.5 6.76794 3.5 7V16.625C3.5 17.3212 3.77656 17.9889 4.26884 18.4812C4.76113 18.9734 5.42881 19.25 6.125 19.25H14.875C15.5712 19.25 16.2389 18.9734 16.7312 18.4812C17.2234 17.9889 17.5 17.3212 17.5 16.625V7C17.5 6.76794 17.4078 6.54538 17.2437 6.38128C17.0796 6.21719 16.8571 6.125 16.625 6.125ZM8.75 5.25C8.75 4.78587 8.93437 4.34075 9.26256 4.01256C9.59075 3.68437 10.0359 3.5 10.5 3.5C10.9641 3.5 11.4092 3.68437 11.7374 4.01256C12.0656 4.34075 12.25 4.78587 12.25 5.25V6.125H8.75V5.25ZM15.75 16.625C15.75 16.8571 15.6578 17.0796 15.4937 17.2437C15.3296 17.4078 15.1071 17.5 14.875 17.5H6.125C5.89294 17.5 5.67038 17.4078 5.50628 17.2437C5.34219 17.0796 5.25 16.8571 5.25 16.625V7.875H7V8.75C7 8.98206 7.09219 9.20462 7.25628 9.36872C7.42038 9.53281 7.64294 9.625 7.875 9.625C8.10706 9.625 8.32962 9.53281 8.49372 9.36872C8.65781 9.20462 8.75 8.98206 8.75 8.75V7.875H12.25V8.75C12.25 8.98206 12.3422 9.20462 12.5063 9.36872C12.6704 9.53281 12.8929 9.625 13.125 9.625C13.3571 9.625 13.5796 9.53281 13.7437 9.36872C13.9078 9.20462 14 8.98206 14 8.75V7.875H15.75V16.625Z" fill="black" />
							</svg>
						</Link>
						<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
							<li><Link className="dropdown-item" to="/profile">Settings</Link></li>
							<li><hr className="dropdown-divider" /></li>
						</ul>
					</li> */}
					<li className="nav-item dropdown notification">
						<span></span>
						<Link className="nav-link dropdown-toggle" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M15.75 11.5325V8.75C15.7487 7.51023 15.3088 6.31091 14.508 5.36443C13.7073 4.41795 12.5974 3.78538 11.375 3.57875V2.625C11.375 2.39294 11.2828 2.17038 11.1187 2.00628C10.9546 1.84219 10.7321 1.75 10.5 1.75C10.2679 1.75 10.0454 1.84219 9.88128 2.00628C9.71719 2.17038 9.625 2.39294 9.625 2.625V3.57875C8.40257 3.78538 7.29271 4.41795 6.49196 5.36443C5.69122 6.31091 5.25126 7.51023 5.25 8.75V11.5325C4.73939 11.713 4.29712 12.0471 3.98382 12.4888C3.67052 12.9306 3.50152 13.4584 3.5 14V15.75C3.5 15.9821 3.59219 16.2046 3.75628 16.3687C3.92038 16.5328 4.14294 16.625 4.375 16.625H7.1225C7.324 17.3664 7.76388 18.021 8.37427 18.4876C8.98467 18.9543 9.73166 19.2071 10.5 19.2071C11.2683 19.2071 12.0153 18.9543 12.6257 18.4876C13.2361 18.021 13.676 17.3664 13.8775 16.625H16.625C16.8571 16.625 17.0796 16.5328 17.2437 16.3687C17.4078 16.2046 17.5 15.9821 17.5 15.75V14C17.4985 13.4584 17.3295 12.9306 17.0162 12.4888C16.7029 12.0471 16.2606 11.713 15.75 11.5325ZM7 8.75C7 7.82174 7.36875 6.9315 8.02513 6.27513C8.6815 5.61875 9.57174 5.25 10.5 5.25C11.4283 5.25 12.3185 5.61875 12.9749 6.27513C13.6313 6.9315 14 7.82174 14 8.75V11.375H7V8.75ZM10.5 17.5C10.1946 17.4982 9.895 17.4164 9.63098 17.2629C9.36695 17.1094 9.1477 16.8895 8.995 16.625H12.005C11.8523 16.8895 11.633 17.1094 11.369 17.2629C11.105 17.4164 10.8054 17.4982 10.5 17.5ZM15.75 14.875H5.25V14C5.25 13.7679 5.34219 13.5454 5.50628 13.3813C5.67038 13.2172 5.89294 13.125 6.125 13.125H14.875C15.1071 13.125 15.3296 13.2172 15.4937 13.3813C15.6578 13.5454 15.75 13.7679 15.75 14V14.875Z" fill="black" />
							</svg>
						</Link>
						<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
							{/* <li><Link className="dropdown-item" to="/profile">Settings</Link></li> */}
							{/* <li><Link className="dropdown-item" to="#!">Activity Log</Link></li> */}
							{/* <li><hr className="dropdown-divider" /></li> */}
							<li><Link className="dropdown-item" to="/notification">Notifications</Link></li>
						</ul>
					</li>
					<li className="nav-item dropdown profile">
						<Link className="nav-link dropdown-toggle" id="navbarDropdown" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<img src={img?img:user} />
						</Link>
						<ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
							<li><Link className="dropdown-item" to="/profile">Profile</Link></li>
							{/* <li><Link className="dropdown-item" to="#!">Activity Log</Link></li> */}
							<li><hr className="dropdown-divider" /></li>
							<li><Link className="dropdown-item" to="/logout">Logout</Link></li>
						</ul>
					</li>
				</ul>
			</nav>
		</div>
	)
}
