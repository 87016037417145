import React from 'react'
import { get_grade } from '../../../apis/Common'
import { useState, useEffect } from 'react';
import Pagination from '../../Pagination';
const Naplan = () => {
    let itemsPerPage = 5;
    const [grades, setGrades] = useState([]);
    const [naplanList, setNaplanList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(naplanList?.length / itemsPerPage);
    const naplansList=[
        {
            title:"NAPLAN 2008 Y3 Past Paper(L.R.N)",
            subject:"E.M.G",
            period:"7 Days",
            price:"Free"
        },
        {
            title: " NAPLAN 2008 Y3 Past Paper(W)",
            subject: "Essay Writing",
            period: "7 Days",
            price: "$15"
        },
        {
            title: "Y3 NAPLAN Trial Test Module 1",
            subject: "WEMG",
            period: "7 Days",
            price: "$75"
        },
        {
            title: "Y3 NAPLAN Trial Test Module 2",
            subject: "WEMG",
            period: "7 Days",
            price: "$75"
        },
        {
            title: " Y3 NAPLAN Trial Test Module 3 (New)",
            subject: "WEMG",
            period: "7 Days",
            price: "$75"
        },
        {
            title: " Year 3 NAPLAN",
            subject: "WEMG",
            period: "7 Days",
            price: "$75"
        }
    ]
    useEffect(() => {
        async function get_data() {
            const grade = await get_grade();
            // const testlist = await assemenent_test_list(currentPage);
            setGrades(grade?.data);
            setNaplanList(naplansList);
        }
        get_data();
    }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = naplanList?.slice(startIndex, endIndex);

    return (
        <div class="row">
            <div class="col-12">
                <div class="grade_content">
                    <h2>Select your Grade</h2>
                    <div class="date-slot activity-sec">
                        <ul>
                            {grades && grades.map((elem) => {
                                return (
                                    <li class="list-group-item">
                                        <input type="radio" id="male" name="Time" />
                                        <a href="javascript:void(0);" class="">
                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.70605 8.22878H18.302" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M14.3866 11.646H14.3947" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.5043 11.646H10.5124" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.61316 11.646H6.62127" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M14.3866 15.0466H14.3947" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M10.5043 15.0466H10.5124" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.61316 15.0466H6.62127" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M14.0381 1.75V4.62943" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M6.96975 1.75V4.62943" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M14.2085 3.13184H6.79959C4.22999 3.13184 2.625 4.56328 2.625 7.19448V15.1129C2.625 17.7855 4.22999 19.25 6.79959 19.25H14.2004C16.7781 19.25 18.375 17.8103 18.375 15.1791V7.19448C18.3831 4.56328 16.7862 3.13184 14.2085 3.13184Z"
                                                    stroke="#2D2926"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                />
                                            </svg>
                                            {elem.name}
                                        </a>
                                    </li>)
                            })}

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="table_head">
                    <h2 class="">NAPLAN List</h2>
                    <p><b>* Free online tests</b> are strictly for students of <b>that grade.</b></p>
                </div>
                <div class="table-responsive tbl_bg">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Period</th>
                                <th scope="col">Price</th>
                                <th scope="col">Buy</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData && currentData?.map((elem)=>{
                                return(
                                    <tr>
                                        <td scope="row">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                               {elem.title}
                                            </button>
                                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-xl">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title" id="staticBackdropLabel">Test Details</h5>
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div class="table-responsive tbl_bg">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">Name</th>
                                                                            <th scope="col">Subject</th>
                                                                            <th scope="col">Period</th>
                                                                            <th scope="col">Price</th>
                                                                            <th scope="col">Buy</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td scope="row">Y5 Assessment English</td>
                                                                            <td>English</td>
                                                                            <td>7 Days</td>
                                                                            <td>Free</td>
                                                                            <td>
                                                                                <div class="cart_btn">
                                                                                    <a href="cart.html">Add to cart</a>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div class="table_head">
                                                                <h2 class="">My Test List</h2>
                                                            </div>
                                                            <div class="table-responsive tbl_bg">
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">S.No</th>
                                                                            <th scope="col">Subject</th>
                                                                            <th scope="col">Name</th>
                                                                            <th scope="col">No. of Questions</th>
                                                                            <th scope="col">Time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td scope="row">1</td>
                                                                            <td>English</td>
                                                                            <td>Y3 NAPLAN Trial Test Module 3.1.Language Conventions</td>
                                                                            <td>48</td>
                                                                            <td>40min</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">2</td>
                                                                            <td>Essay Writing</td>
                                                                            <td>Y3 NAPLAN Trial Test Module 3.2.Writing</td>
                                                                            <td>48</td>
                                                                            <td>40min</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">3</td>
                                                                            <td>English</td>
                                                                            <td>Y3 NAPLAN Trial Test Module 3.3.Reading</td>
                                                                            <td>48</td>
                                                                            <td>40min</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td scope="row">4</td>
                                                                            <td>Maths</td>
                                                                            <td>Y3 NAPLAN Trial Test Module 3.4.Numeracy</td>
                                                                            <td>48</td>
                                                                            <td>40min</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{elem.subject}</td>
                                        <td>{elem.period}</td>
                                        <td>{elem.price}</td>
                                        <td>
                                            <div class="cart_btn">
                                                <a href="cart.html">Add to cart</a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                          
                        </tbody>
                    </table>
                    <div class="pagin">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={onPageChange}
                            />
                    </div>
                </div>
                <div class="tbl_content">
                    <ol type="1">
                        <li>* If you are not enrolled in CS Online School, please <span class="join">Join Membership first</span> and then take the test.</li>
                        <li>You can take the test ONLY ONCE during the available period.</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
export default Naplan;