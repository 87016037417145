import { HTTPURL } from '../../constants/Matcher';

export async function get_profile() {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-register_edit'
            , {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('Token')}`
                }
            });
        const responseData = await response.json();
        localStorage.setItem('profileImg',responseData?.data[0].image_url)
        // if(responseData.status)
        // {
        //     (responseData?.message);
        // } else {
        //    (responseData?.message);
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



// item update 
export async function update_profile(data) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('Token')}`);

    try {
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: data,
            redirect: 'follow'
        };

        const response = await fetch(HTTPURL + `api/student/student-register_update`, requestOptions);
        const responseData = await response.json();
        // if (responseData.status) {
        //     toast.success(responseData?.message);
        // } else {
        //     toast.error(responseData?.message);
        // }

        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



