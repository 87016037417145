export const FreevalidateStep = (step, data) => 
{
    const validationErrors = {};
    switch (step) {
        case 1:
            if (!data.name) {
                validationErrors.name = 'Please enter your name.';
            }
            if (!data.grade) {
                validationErrors.grade = 'Please select an option.';
            }
            // if (!data.term_id) {
            //     validationErrors.term_id = 'Please select an option.';
            // }
            if (!data.email) {
                validationErrors.email = 'Please enter your email.';
            }
            if (!data.mobile) {
                validationErrors.mobile = 'Please enter your mobile no.';
            }
            if (!data.date_of_birth) {
                validationErrors.date_of_birth = 'Please enter your dob.';
            }
            if (!data.school) {
                validationErrors.school = 'Please enter your school name.';
            }
            // if (!data.branch) {
            //     validationErrors.branch = 'Please select an option.';
            // }
            // if (!data.subject_id) {
            //     validationErrors.subject_id = 'Please select an option.';
            // }
         
            break;
        case 2:
            if (!data.email) {
                validationErrors.email = 'Please enter your userId.';
            }
            if (!data.password) {
                validationErrors.password = 'Password is required.';
            } else if (data.password.length < 6) {
                validationErrors.password =
                  'Password must be at least 6 characters long.';
            }
            break;
           default:
            break;
    }
    return validationErrors;
}