import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { HTTPURL } from '../../../constants/Matcher';
import { getlectureData_List } from '../../../apis/My-e-Test/Classes';
import jsPDF from 'jspdf';
const LectureDetail = () => {
    const [lectureList, setLectureList] = useState([]);
    const [lessonData, setLecssonData] = useState([]);
    const [lectureData, setLectureData] = useState([]);
    const [pdf, setPdf] = useState();
    const { id, c_id } = useParams();
    useEffect(() => {
        async function get_data() {
            const lectureList = await getlectureData_List(id, c_id);
            setLectureList(lectureList?.data?.data);
            setLecssonData(JSON.parse(lectureList?.data?.data[0]?.question_data))

            setLectureData(JSON.parse(lectureList?.data?.lectures[0]?.lecture))
            setPdf(lectureData[0]);
        }
        get_data();
    }, []);
      const handleOpenPDF = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
      };
    return (
        <div className="row">
            <div className="col-12">
                <div className="profile_shade">
                    <div className="grade_content profile_title">
                        <h2>Lecture</h2>
                    </div>
                    <hr className="m-0" />
                    <div className="row">
                        {lectureList && lectureList?.map((elem) => {
                            const dateTimeComponents = elem?.created_at?.split(' ');
                            const dateComponents = dateTimeComponents[0]?.split('-');
                            const timeComponents = dateTimeComponents[1]?.split(':');
                            const year = parseInt(dateComponents[0]);
                            const month = parseInt(dateComponents[1]) - 1; // Months are zero-indexed
                            const day = parseInt(dateComponents[2]);
                            const hours = parseInt(timeComponents[0]);
                            const minutes = parseInt(timeComponents[1]);
                            const seconds = parseInt(timeComponents[2]);
                            const formattedDate = new Date(year, month, day, hours, minutes, seconds).toISOString().substring(0, 10);

                            return (
                                <div className="col-12">
                                    <div className="profile_cont">
                                        <div className="profile_inner_cont">
                                            <h2>Subject</h2>
                                            <h4>{elem.subject}</h4>
                                        </div>
                                        <div className="profile_main">
                                            <div className="profile_inner_cont ">
                                                <h2>Grade</h2>
                                                <h4>{elem.grade}</h4>

                                            </div>
                                        </div>
                                        <div className="profile_inner_cont">
                                            <h2>Question</h2>
                                            <h4>{elem.question_name}</h4>
                                        </div>
                                    </div>
                                    <div className="profile_cont">

                                        <div className="profile_main">
                                            <div className="profile_inner_cont">
                                                <h2>Time</h2>
                                                <h4>{elem.time} mint</h4>
                                            </div>
                                        </div>
                                        <div className="profile_inner_cont">
                                            <h2>Date</h2>
                                            <h4>{formattedDate}</h4>
                                        </div>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="profile_add">
                                        <h2>Lecture Detail</h2>
                                    </div>
                                    
                                    <div className='question-lec'><b>Questions </b></div>
                                    {lessonData && lessonData?.map((elem, i) => {
                                        return (
                                            <>
                                                <div className="profile_cont questionair-sec">
                                                    <div className="profile_inner_cont profile_inner_add">

                                                        <span>({i + 1}) {elem.question}</span>
                                                    </div>
                                                    {/* <div className="profile_inner_cont">
                                                        <h2>Title</h2>
                                                        <h4>{elem.title}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Title</h2>
                                                        <h4>{elem.title}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Day</h2>
                                                        <h4>{elem.day}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Date</h2>
                                                        <h4>{elem.date}</h4>
                                                    </div> */}
                                                </div>
                                                {/* <div className="profile_cont ">
                                                    <div className="profile_inner_cont profile_inner_add">
                                                        <h2>Online Class</h2>
                                                        <h4>{elem.google_meet_link}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Offline Class</h2>
                                                        <h4>{elem.title}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Start Time</h2>
                                                        <h4>{elem.start_time}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>End Time</h2>
                                                        <h4>{elem.end_time}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Study File</h2>
                                                        <iframe src={`${HTTPURL}uploads/${elem.file}`} ></iframe>
                                                    </div>
                                                </div> */}
                                                {/* <div className="profile_cont ">
                                                    <div className="profile_inner_cont">
                                                        <h2>Descrtiotion</h2>
                                                        <h4>{elem.descriptions}</h4>
                                                    </div>
                                                </div> */}
                                            </>

                                        )

                                    })}
                                    
                                    {lectureData && lectureData?.map((elem, i) => {
                                        //    {(elem)=>setPdf(elem?.u_document)} 
                                        return (

                                            <>
                                                <div className="profile_cont questionair-sec">
                                                    {i == 0 ?
                                                        <div className="profile_inner_cont">
                                                            <h2>Start Date</h2>
                                                            <h4>{elem.start_date}</h4>
                                                        </div> : ''}
                                                    <div className="profile_inner_cont">
                                                        <h2>link</h2>
                                                        <h4>{elem.link}</h4>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Image</h2>
                                                        <img src={`${HTTPURL}uploads/courses/${elem.u_image}`} alt=""  />
                                                    </div>
                                                </div>
                                                <div className="profile_cont questionair-sec">
                                                    <div className="profile_inner_cont profile_inner_add">
                                                        <h2>Document</h2>
                                                        <h4>{elem.u_document}</h4>
                                                        {console.log(`${HTTPURL}uploads/courses/${elem.u_document}`)}
                                                        <button onClick={() => handleOpenPDF(`${HTTPURL}uploads/courses/${elem.u_document}`)}>Download</button>
                                                    </div>
                                                    <div className="profile_inner_cont">
                                                        <h2>Audio</h2>
                                                        <audio controls>
                                                            <source src={`${HTTPURL}uploads/courses/${elem.u_audio}`} type="audio/mpeg" />
                                                            Your browser does not support the audio element.
                                                        </audio>
                                                    </div>
                                                </div>
                                                
                                            </>
                                        )

                                    })}
                                </div >
                            )

                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LectureDetail;
