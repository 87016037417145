import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getClassList } from '../../../apis/My-e-Test/ClassTest';
import Pagination from '../../Pagination';

const ClassList = () => {
    let itemsPerPage = 5;
    const [workList, setWorkList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); 
    const totalPages = Math.ceil(workList?.length / itemsPerPage);
    useEffect(() => {
        async function get_data() {
            const worklist = await getClassList(currentPage);
            setWorkList(worklist?.data);
        }
        get_data();
    }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = workList?.slice(startIndex, endIndex);

    return (
        <div className="row">
            <div className="col-12">
                <div className="table_head">
                    <h2 className="">Classes</h2>
                </div>
                <div className="table-responsive tbl_bg">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Subject</th>
                                <th scope="col">Class</th>
                                <th scope="col">Test</th>
                                <th scope="col">Time</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentData.length >0 ?
                                currentData && currentData?.map((elem) => {
                                    return (
                                        <tr>
                                            <td scope="row">{elem?.subject}</td>
                                            <td>{`${elem?.c_name}`}</td>
                                            <td>{`${elem?.test_type}`}</td>
                                            <td>{`${elem?.time} min`}</td>
                                            <td>
                                                 <Link target='_blank' className='edit-btn' to={`/my-e-test/questionaire/${elem?.id}`}>Take Test</Link>
                                                </td>

                                        </tr>
                                    )
                                }):
                                <p>No Data</p>
                            }
                        </tbody>
                    </table>
                    <div className="pagin">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
                <div className="tbl_content">
                    <ol type="1">
                        <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li>
                        <li>You can take the test ONLY ONCE during the available period.</li>
                    </ol>
                </div>
            </div>
        </div>

    )
}

export default ClassList



// import React from 'react';
// import { useState, useEffect,useRef } from 'react';
// import { getquestionList } from '../../../apis/My-e-Test/ClassTest'
// const ClassList = () => {
//     const [questions, setQuestions] = useState([]);
//     const [currentQuestion, setCurrentQuestion] = useState(0);
//     const [correct, setCorrect] = useState(0);
//     const [inProgress, setInProgress] = useState(true);

//     useEffect(() => {
//         async function get_data() {
//             const profile = await getquestionList();
//             setQuestions(profile?.data);
//             console.log('data', profile?.data);
//         }
//         get_data();
//     }, []);


//     const radioRef = useRef(null);

//     const updateAnswer = (e) => {
//         const answerValue = e.target.value;
//         const updatedQuestions = [...questions];
//         updatedQuestions[currentQuestion].answerCorrect = JSON.parse(answerValue);
//         setQuestions(updatedQuestions);
//         updatedQuestions[currentQuestion].checked = true;
//     };



//     const nextQuestion = () => {
//         setCurrentQuestion(currentQuestion + 1);
//         radioRef.current.reset();
//     };
//     const previousQuestion = () => {
//         setCurrentQuestion(currentQuestion - 1);
//         radioRef.current.reset();
//     };
//     const getResults = () => {
//         const correctCount = questions.reduce((count, item) => (item.answerCorrect ? count + 1 : count), 0);
//         setCorrect(correctCount);
//         setInProgress(false);
//     };
//     console.log('correct',correct);
//     const currentQuestionData = questions[currentQuestion];
//     console.log('currentQuestionData', currentQuestionData );

//     return (
//         <section className="quiz fade-in" aria-live="polite">
//             <div className="question-container">
//                 <div className='count-ques-main'>
//                     <div className='count-ques'>
//                         <p className="counter">
//                             <span className='ques-no'><b>Question</b> {currentQuestion + 1} of {questions.length}</span>
//                         </p>
//                     </div>
//                     <div className="progress-main">
//                         <div className="progress">

//                             <div className="progress-bar" style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}></div>
//                         </div>
//                     </div>
//                 </div>
//               {
//                     currentQuestionData && (
//                         <>
//                             <div className='ques-img'>
//                                 {currentQuestionData.file && <img className="img-fluid" src={currentQuestionData.file} alt='question image' />
//                                 }
//                             </div>
//                             <p className="question"> <b>Question :-</b>
//                                 <span dangerouslySetInnerHTML={{ __html: currentQuestionData.question }} /></p>
//                             {currentQuestionData.passage && (
//                                 <div className="fade-in">
//                                     <p>
//                                         <b>Passage :-</b> <span dangerouslySetInnerHTML={{ __html: currentQuestionData.passage }} />
//                                     </p>
//                                 </div>
//                             )}
//                             <form ref={radioRef}>
//                                 <b>Options  :-</b>
//                                 <div className='option-main'>
//                                     {currentQuestionData.options.map((item, index) => (
//                                         <div key={index} className={'option'}
//                                         >

//                                             <input
//                                                 id={`radio-${index}`}
//                                                 onClick={updateAnswer}
//                                                 type="radio"
//                                                 name="option"
//                                                 value={item.correct}
//                                             // disabled={currentQuestionData.checked}
//                                             />
//                                             <label htmlFor={`radio-${index}`}>
//                                                 <span dangerouslySetInnerHTML={{ __html: item.option }} />
//                                             </label>
//                                         </div>


//                                     ))}
//                                 </div>
//                             </form>

//                             <div className="bottom">
//                                 {currentQuestion >= 1 ? <button className="fade-in next" type="button" onClick={previousQuestion}>
//                                     <i className="fa fa-arrow-left "></i> Previous
//                                 </button> : ''}

//                                 {currentQuestionData.checked ? (
//                                     <button className="fade-in next" type="button" onClick={nextQuestion}>
//                                         Next <i className="fa fa-arrow-right"></i>
//                                     </button>
//                                 ) : ''}
//                                   {currentQuestion + 1 === questions.length && currentQuestionData.checked && (
//           <button type="button" className="get-results pulse" onClick={getResults}>
//             Get Results
//           </button>
//         )}
//                             </div>

//                             {!inProgress && (
//         <div className="results fade-in">
//             <div className="score">
//             <h1>Your score: {((correct / questions.length) * 100).toFixed()}%</h1>
//             </div>
        
//         </div>
//       )}
//                         </>
//                     )
//               }
               

//             </div>
//         </section>
     
//     );
// };

// export default ClassList;


