import { HTTPURL } from '../constants/Matcher';



//login_student
export async function login_student(data) {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-login', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            //  responseData?.message;
            console.log(responseData?.data);
            var now = new Date().getTime();
            localStorage.setItem('setupTime', now); 
            localStorage.setItem('Token', responseData?.data?.token);
            localStorage.setItem('name', responseData?.data?.name);
            localStorage.setItem('studentId', responseData?.data?.studentId);
            localStorage.setItem('grade', responseData?.data?.grade);
            localStorage.setItem('studentType', responseData?.data?.student_type);
            localStorage.setItem('userImage', responseData?.data?.image);
        }
        // else {
        //   responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




//signup_student
export async function signup_student(data) {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-signup', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            console.log(responseData?.data);
            localStorage.setItem('singupToken', responseData?.data);
       
        }
        else {
            console.log('error');
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//free assesment form
export async function free_assesment_form(data) {
    try {
        const response = await fetch(HTTPURL + 'api/student/free-assessment-form', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData?.status) {
            console.log(responseData?.data);
            localStorage.setItem('singupToken',JSON.stringify(responseData?.data));
        }
        else {
            console.log('error');
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


//login_student_with_captcha
export async function login_student_with_captcha(token,data) {
    try {
        const response = await fetch(HTTPURL + 'api/student/student-login-with-token', {
            method: "GET",
             headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        if (responseData?.status) {
            //  responseData?.message;
            console.log(responseData?.data);
            var now = new Date().getTime();
            localStorage.setItem('setupTime', now); 
            localStorage.setItem('Token', responseData?.data?.token);
            localStorage.setItem('name', responseData?.data?.name);
            localStorage.setItem('studentId', responseData?.data?.studentId);
            localStorage.setItem('grade', responseData?.data?.grade);
            localStorage.setItem('studentType', responseData?.data?.student_type);
            localStorage.setItem('userImage', responseData?.data?.image);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}