import React from 'react'

export default function Footer() {
  return (
    <footer class="py-2 footer-bg">
      <div class="container-fluid px-md-4">
        <div class="d-flex align-items-center justify-content-between small flex-column flex-md-row">
          <div class="text-muted">© Copyright 2007-2023 CS Education & CS Online School</div>
          <div class="policy">
            <a href="#">Our Branches :  VIC   |   QLD   |   SA</a>
          </div>
        </div>
      </div>
    </footer>
  )
}
