import React, { useState, useEffect } from 'react'
import EventDetail from './EventDetail';
import { get_events, get_student_question_list, get_course_by_grade } from '../../apis/Dashboard'
import { Pagination, Pagination1 } from './pagination';
import { HTTPURL } from '../../constants/Matcher';
const Dashboard = () => {
    const [open, setOpen] = useState(false);
    const [id,setId]=useState('');
    const [events, setEvents] = useState([]);
    const [course, setCourse] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const studentType = localStorage.getItem('studentType')
    //pagination 
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
    const itemsPerPage = 5;
    const totalItems = questionList?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const totalItems1 = events?.length;
    const totalPages1 = Math.ceil(totalItems1 / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const indexOfLastItem1 = currentPage1 * itemsPerPage;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage;
    const currentItems = questionList?.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems1 = events?.slice(indexOfFirstItem1, indexOfLastItem1);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePageChange1 = (pageNumber) => {
        setCurrentPage1(pageNumber);
    };
    useEffect(() => {
        async function get_data() {
            const event = await get_events();
            const question_list = await get_student_question_list();
            const course = await get_course_by_grade();
            setEvents(event?.data);
            setQuestionList(question_list?.data);
            setCourse(course?.data)
        }
        get_data();
    }, []);
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = today.getMonth();
    let yyyy = today.getFullYear();
    today = dd + mm + yyyy;
    return (
        <div className='dashboard_n'>
            <div className="row">
                <div className="col-xl-7 ">
                    {studentType == 1 ?
                        <div className="row">
                            <div className="col-xl-12 col-md-12 new_test">
                                {/* <h4 className="sap_lg_heading">New e-Test</h4> */}
                            </div>
                            {questionList?.slice(0, 2).map((elem) => {
                                return (
                                    <div className="col-xl-6 col-md-6">
                                        {/* {elem?.end_time > today ?
                                            <div className="test-card">
                                                <img src="images/test1.png" />
                                                <div className="test-card-content">
                                                    <h3>{elem?.question_name}</h3>
                                                    <a href="#">for Y5 & 6 (10 Sets)</a>
                                                    <a href="#">{elem?.test_type}</a>
                                                </div>
                                                <div className="test-action free">
                                            <a href="#">Free Entrance Tests</a>
                                        </div>
                                            </div> : ''
                                        } */}

                                    </div>
                                )
                            })}
                        </div> : ''}
                        <div className="row">
                                <div className="event">
                                    <h2 className="All e-Test list">School Events</h2>
                                </div>
                                <div className="col-12">
                                    <div className="sch_event">
                                        {
                                            currentItems1 && currentItems1?.map((e) => {
                                                return (
                                                    <>
                                                        <div className="sch_event_main">
                                                            <div className="sch_event_inner">
                                                                <div className="july_num">
                                                                    <h2>{e?.date}</h2>
                                                                    <h4>{e?.month}</h4>
                                                                </div>
                                                                <div className="evnt_head">
                                                                    <h2>{e?.title}</h2>
                                                                    <h4>{e?.date_time}</h4>
                                                                </div>
                                                            </div>
                                                            <div className="sch_view">
                                                                <button onClick={() =>( setOpen(true),setId(e?.id))}>View</button>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                )
                                            })
                                        }
                                        <div className="pagin">
                                            <Pagination
                                                currentPage={currentPage1}
                                                totalPages={totalPages1}
                                                onPageChange={handlePageChange1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                <div className="col-xl-5 ">
                    <div className="row">
                        <div className="col-xl-12 col-md-12">
                            {studentType == 1 ?
                                <>
                                    <div className="new_test">
                                        <h4 className="sap_lg_heading">All e-Test list</h4>
                                    </div>
                                    <div className="test-card all-test">
                                        <div>
                                            <ul>
                                                {/* {currentItems?.map((elem) => {
                                                    return (
                                                        <li>
                                                            {elem?.end_time > today ?
                                                                <>
                                                                    <div className="test-listing">
                                                                        <div className="test-card-content">
                                                                            <h3>{elem.question_name}</h3>
                                                                            <a href="#">{elem?.test_type}</a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="test-action">
                                                                        <a href="#">
                                                                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M13.5979 9.87884L8.64538 4.93509C8.56404 4.85308 8.46726 4.78799 8.36064 4.74356C8.25401 4.69914 8.13964 4.67627 8.02413 4.67627C7.90862 4.67627 7.79425 4.69914 7.68763 4.74356C7.581 4.78799 7.48422 4.85308 7.40288 4.93509C7.23991 5.09904 7.14844 5.32081 7.14844 5.55197C7.14844 5.78313 7.23991 6.0049 7.40288 6.16884L11.7341 10.5438L7.40288 14.8751C7.23991 15.039 7.14844 15.2608 7.14844 15.492C7.14844 15.7231 7.23991 15.9449 7.40288 16.1088C7.48392 16.1915 7.58056 16.2573 7.6872 16.3024C7.79384 16.3474 7.90836 16.3709 8.02413 16.3713C8.1399 16.3709 8.25442 16.3474 8.36106 16.3024C8.4677 16.2573 8.56434 16.1915 8.64538 16.1088L13.5979 11.1651C13.6867 11.0832 13.7576 10.9837 13.8061 10.873C13.8545 10.7623 13.8796 10.6428 13.8796 10.522C13.8796 10.4011 13.8545 10.2816 13.8061 10.1709C13.7576 10.0602 13.6867 9.96078 13.5979 9.87884Z" fill="#EE3124" />
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </>
                                                                : ''}
                                                        </li>
                                                    )
                                                })} */}
                                            </ul>
                                        </div>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>
                                </>
                                : ''}
                            {/* <div className="notific_head">
                                <h2 className="sap_lg_heading">Notifications</h2>
                            </div>
                            <div className="notific">
                                <div className="notific_inner">
                                    <div className="notific_img">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 13.3097H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 17.1962H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0433 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.96515 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="notific_cont">
                                        <p>Lorem ipsum dolor sit amet, consectetur...</p>
                                        <h4>Just Now</h4>
                                    </div>
                                </div>
                                <hr />
                                <div className="notific_inner">
                                    <div className="notific_img">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 13.3097H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 17.1962H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0433 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.96515 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="notific_cont">
                                        <p>Lorem ipsum dolor sit amet, consectetur...</p>
                                        <h4>Just Now</h4>
                                    </div>
                                    <hr />
                                </div>
                                <hr />
                                <div className="notific_inner">
                                    <div className="notific_img">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 13.3097H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 17.1962H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0433 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.96515 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="notific_cont">
                                        <p>Lorem ipsum dolor sit amet, consectetur...</p>
                                        <h4>Just Now</h4>
                                    </div>
                                    <hr />
                                </div>
                                <hr />
                                <div className="notific_inner">
                                    <div className="notific_img">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.09277 9.40421H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 13.3097H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.55818 17.1962H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M16.0433 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.96515 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2383 3.5791H7.77096C4.83427 3.5791 3 5.21504 3 8.22213V17.2718C3 20.3261 4.83427 21.9999 7.77096 21.9999H16.229C19.175 21.9999 21 20.3545 21 17.3474V8.22213C21.0092 5.21504 19.1842 3.5791 16.2383 3.5791Z" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <div className="notific_cont">
                                        <p>Lorem ipsum dolor sit amet, consectetur...</p>
                                        <h4>Just Now</h4>
                                    </div>
                                    <hr />
                                </div>
                                <hr />
                                <div className="pagin">
                                    <div className="listleft_arrow">
                                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.402119 5.87884L5.35462 0.935093C5.43596 0.853081 5.53274 0.787986 5.63936 0.743563C5.74599 0.699141 5.86036 0.67627 5.97587 0.67627C6.09138 0.67627 6.20575 0.699141 6.31237 0.743563C6.419 0.787986 6.51578 0.853081 6.59712 0.935093C6.76009 1.09904 6.85156 1.32081 6.85156 1.55197C6.85156 1.78313 6.76009 2.0049 6.59712 2.16884L2.26587 6.54384L6.59712 10.8751C6.76009 11.039 6.85156 11.2608 6.85156 11.492C6.85156 11.7231 6.76009 11.9449 6.59712 12.1088C6.51608 12.1915 6.41944 12.2573 6.3128 12.3024C6.20616 12.3474 6.09164 12.3709 5.97587 12.3713C5.8601 12.3709 5.74558 12.3474 5.63894 12.3024C5.5323 12.2573 5.43566 12.1915 5.35462 12.1088L0.402119 7.16509C0.313302 7.08316 0.242419 6.98371 0.193938 6.87302C0.145457 6.76234 0.120428 6.64281 0.120428 6.52197C0.120428 6.40113 0.145457 6.2816 0.193938 6.17091C0.242419 6.06023 0.313302 5.96078 0.402119 5.87884Z" fill="black" />
                                        </svg>

                                    </div>
                                    <div className="list_num">
                                        <h2><span className="first">1</span> 2 3</h2>
                                    </div>
                                    <div className="listright_arrow">
                                        <svg width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.59788 5.87884L1.64538 0.935093C1.56404 0.853081 1.46726 0.787986 1.36064 0.743563C1.25401 0.699141 1.13964 0.67627 1.02413 0.67627C0.908621 0.67627 0.794253 0.699141 0.687626 0.743563C0.580999 0.787986 0.484224 0.853081 0.402881 0.935093C0.239911 1.09904 0.148438 1.32081 0.148438 1.55197C0.148438 1.78313 0.239911 2.0049 0.402881 2.16884L4.73413 6.54384L0.402881 10.8751C0.239911 11.039 0.148438 11.2608 0.148438 11.492C0.148438 11.7231 0.239911 11.9449 0.402881 12.1088C0.483919 12.1915 0.580558 12.2573 0.687199 12.3024C0.79384 12.3474 0.908362 12.3709 1.02413 12.3713C1.1399 12.3709 1.25442 12.3474 1.36106 12.3024C1.4677 12.2573 1.56434 12.1915 1.64538 12.1088L6.59788 7.16509C6.6867 7.08316 6.75758 6.98371 6.80606 6.87302C6.85454 6.76234 6.87957 6.64281 6.87957 6.52197C6.87957 6.40113 6.85454 6.2816 6.80606 6.17091C6.75758 6.06023 6.6867 5.96078 6.59788 5.87884Z" fill="black" />
                                        </svg>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {open ? <EventDetail open={open} setOpen={setOpen} id={id} /> : null}
                <div className="col-xl-12 col-md-12 new_test">
                    <h4 className="sap_lg_heading">Course</h4>
                </div>
                <div className='row'>
                        {course?.map((course) => (
                            <div className='col-md-7 align-item-center course-sec'>  
                                <ul>
                                    <li>
                                        <div className='test-card'>
                                            <img src={`${HTTPURL}uploads/${course.image}`} height={200} width={200} />
                                            <div className="cag-title">{course.name}</div>
                                        </div>
                                    </li>   
                                </ul>
                            </div>

                        ))}
                </div>
            

                {/* <div className="table-responsive tbl_bg">
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">No</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    course && course?.map((elem,i) => {
                                        console.log(elem.time)
                                        return (
                                            <tr>
                                                <td>{i+1}</td>
                                                <td>{elem?.name}</td>
                                                <td>AUD {elem?.price} </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> */}


            </div>
        </div>
    )
}
export default Dashboard;
