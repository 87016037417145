import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { get_branch, get_course, get_grade, get_country, get_state } from '../../../apis/Common';
import { get_profile, update_profile } from '../../../apis/profile/Profile';
import user from '../../../assets/Images/user.png'
const EditProfile = () => {
    const [image, setImage] = useState({ preview: '', data: '' });
    const [API_image, setAPI_image] = useState("");
    const inputFile = useRef(null);
    const [country, setCountry] = useState([]);
    const [countryId, setCountryId] = useState('');
    const [state, setState] = useState([]);
    const [grades, setGrades] = useState([]);
    const [course, setCourse] = useState([]);
    const [branch, setBranch] = useState([]);
    console.log('countryId', countryId);
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        async function get_data() {
            const result = await get_profile();
            const dateComponents = result?.data[0]?.date_of_birth?.split('-');
            const year = parseInt(dateComponents[0]);
            const month = parseInt(dateComponents[1]) - 1;
            const day = parseInt(dateComponents[2]);
            const utcDate = new Date(Date.UTC(year, month, day, 0, 0, 0));
            const formattedDate = utcDate.toISOString().substring(0, 10);
            var { image_url, first_name, last_name, gender,
                school, grade, course, branch, post_code, address, city,
                state, country, student_number, email, p_first_name, p_last_name,
                father_mobilenumber, p_email, email } = result?.data[0];
            setAPI_image(image_url)
            setValue('image_url', image_url);
            setValue('first_name', first_name);
            setValue('last_name', last_name);
            setValue('gender', gender);
            setValue('date_of_birth', `${formattedDate}`);
            setValue('school', school);
            setValue('grade', grade);
            setValue('course', course);
            setValue('branch', branch);
            setValue('post_code', post_code);
            setValue('address', address);
            setValue('city', city);
            setTimeout(()=>{

                setValue('country', country);
                setCountryId(country)
            },1000)
            setValue('state', state);
            setValue('student_number', student_number);
            setValue('email', email);
            setValue('p_first_name', p_first_name);
            setValue('p_last_name', p_last_name);
            setValue('father_mobilenumber', father_mobilenumber);
            setValue('p_email', p_email);
            setValue('email', email);
        }
        get_data();
    }, []);
    useEffect(() => {
        async function get_data() {
            const country = await get_country();
            // const state = await get_state();
            const grade = await get_grade();
            setGrades(grade?.data);
            setCountry(country?.data);
            // setState(state?.data)
        }
        get_data();
    }, []);

    //get state
    useEffect(() => {
        async function get_data() {
            const state = await get_state(countryId);
            setState(state?.data);
        }
        get_data();
    }, [countryId]);
    useEffect(() => {
        async function get_data() {
            const course = await get_course();
            setCourse(course?.data);
        }
        get_data();
    }, []);


    useEffect(() => {
        async function get_data() {
            const branch = await get_branch();
            setBranch(branch?.data);
        }
        get_data();
    }, []);
    const handleFileUpload = e => {
        const { files } = e.target;
        if (files && files.length) {
            const filename = files[0].name;
            var parts = filename.split(".");
            const fileType = parts[parts.length - 1];

            const img = {
                preview: URL.createObjectURL(e.target.files[0]),
                data: e.target.files[0],
            }
            setImage(img);
        }
    };
    const onButtonClick = () => {
        inputFile.current.click();
    };
    const navigate = useNavigate();
    const onSubmit = async (data) => {
        const form = new FormData();
        form.append("profile", image?.data);
        form.append("first_name", getValues('first_name'));
        form.append("last_name", getValues('last_name'));
        form.append("gender", getValues('gender'));
        form.append("date_of_birth", getValues('date_of_birth'));
        form.append("school", getValues('school'));
        form.append("grade", getValues('grade'));
        form.append("city", getValues('city'));
        form.append("address", getValues('address'));
        form.append("post_code", getValues('post_code'));
        form.append("branch", getValues('branch'));
        form.append("course", getValues('course'));
        form.append("state", getValues('state'));
        form.append("country", getValues('country'));
        form.append("student_number", getValues('student_number'));
        form.append("email", getValues('email'));
        form.append("p_first_name", getValues('p_first_name'));
        form.append("p_last_name", getValues('p_last_name'));
        form.append("father_mobilenumber", getValues('father_mobilenumber'));
        form.append("p_email", getValues('p_email'));
        form.append("password", getValues('password'));

        const res = await update_profile(form);

        if (res?.status) {
            return navigate('/profile')
        }
    }
    return (
        <div className="row">
            <div className="col-12">
                <div className="profile_shade">
                    <div className="grade_content profile_title">
                        <h2>Your Profile</h2>
                    </div>

                    <hr className="m-0" />
                    <div className="row">
                        <Form className="form-horizontal" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} >
                            <div className="col-12">
                                <div className="update_feild">
                                    <div className="row align-items-center">
                                        <div className="col-lg-2">
                                            <div className="profile_menimg position-relative">
                                                <img className="edit-profile-pic" src={image?.preview || API_image ? image?.preview || API_image : user} alt="profile" />
                                                <div className="edit_icon" onClick={() => onButtonClick()}>
                                                    <input type='file' ref={inputFile} onChange={handleFileUpload} style={{ display: "none" }} />
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.75 13.5H6.93C7.0287 13.5006 7.12655 13.4817 7.21793 13.4443C7.30931 13.407 7.39242 13.352 7.4625 13.2825L12.6525 8.08502L14.7825 6.00002C14.8528 5.93029 14.9086 5.84734 14.9467 5.75595C14.9847 5.66455 15.0043 5.56652 15.0043 5.46752C15.0043 5.36851 14.9847 5.27048 14.9467 5.17908C14.9086 5.08769 14.8528 5.00474 14.7825 4.93502L11.6025 1.71752C11.5328 1.64722 11.4498 1.59142 11.3584 1.55335C11.267 1.51527 11.169 1.49567 11.07 1.49567C10.971 1.49567 10.873 1.51527 10.7816 1.55335C10.6902 1.59142 10.6072 1.64722 10.5375 1.71752L8.4225 3.84002L3.2175 9.03752C3.14799 9.1076 3.093 9.19071 3.05567 9.28209C3.01835 9.37346 2.99943 9.47131 3 9.57001V12.75C3 12.9489 3.07902 13.1397 3.21967 13.2803C3.36032 13.421 3.55109 13.5 3.75 13.5ZM11.07 3.30752L13.1925 5.43002L12.1275 6.49502L10.005 4.37252L11.07 3.30752ZM4.5 9.87752L8.9475 5.43002L11.07 7.55252L6.6225 12H4.5V9.87752ZM15.75 15H2.25C2.05109 15 1.86032 15.079 1.71967 15.2197C1.57902 15.3603 1.5 15.5511 1.5 15.75C1.5 15.9489 1.57902 16.1397 1.71967 16.2803C1.86032 16.421 2.05109 16.5 2.25 16.5H15.75C15.9489 16.5 16.1397 16.421 16.2803 16.2803C16.421 16.1397 16.5 15.9489 16.5 15.75C16.5 15.5511 16.421 15.3603 16.2803 15.2197C16.1397 15.079 15.9489 15 15.75 15Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-10">
                                            <div className="checkout_field edit_field">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">First Name</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.42857"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name"
                                                                {...register("first_name")} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Last Name</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.42857"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name"
                                                                {...register("last_name")} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Gender</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.42857"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Male"
                                                                {...register("gender")} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Date of Birth</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.09277 9.40427H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.55818 13.3097H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.55818 17.1962H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M16.0433 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.96515 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M16.2383 3.57919H7.77096C4.83427 3.57919 3 5.21513 3 8.22222V17.2719C3 20.3262 4.83427 22 7.77096 22H16.229C19.175 22 21 20.3546 21 17.3475V8.22222C21.0092 5.21513 19.1842 3.57919 16.2383 3.57919Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <input type="date" className="form-control" id="exampleFormControlInput1" placeholder="Date of birth"
                                                                {...register("date_of_birth")} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">School Name</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="School Name"
                                                                {...register("school")} disabled />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Grade</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.09277 9.40427H20.9167" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M16.442 13.3097H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M12.0045 13.3097H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.55818 13.3097H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M16.442 17.1962H16.4512" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M12.0045 17.1962H12.0137" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.55818 17.1962H7.56744" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M16.0433 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path d="M7.96515 2V5.29078" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M16.2383 3.57919H7.77096C4.83427 3.57919 3 5.21513 3 8.22222V17.2719C3 20.3262 4.83427 22 7.77096 22H16.229C19.175 22 21 20.3546 21 17.3475V8.22222C21.0092 5.21513 19.1842 3.57919 16.2383 3.57919Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <select className="form-select" aria-label="Default select example"
                                                                {...register("grade")} disabled>
                                                                <option value='grade'>grade</option>
                                                                {grades && grades.map((elem) => {
                                                                    return (
                                                                        <option value={elem.id}>{elem.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Course</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M11.9951 16.6768V14.1398" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        clip-rule="evenodd"
                                                                        d="M18.19 5.3302C19.88 5.3302 21.24 6.7002 21.24 8.3902V11.8302C18.78 13.2702 15.53 14.1402 11.99 14.1402C8.45 14.1402 5.21 13.2702 2.75 11.8302V8.3802C2.75 6.6902 4.12 5.3302 5.81 5.3302H18.19Z"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M15.4951 5.32582V4.95982C15.4951 3.73982 14.5051 2.74982 13.2851 2.74982H10.7051C9.48512 2.74982 8.49512 3.73982 8.49512 4.95982V5.32582"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M2.77441 15.483L2.96341 17.992C3.09141 19.683 4.50041 20.99 6.19541 20.99H17.7944C19.4894 20.99 20.8984 19.683 21.0264 17.992L21.2154 15.483"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <select className="form-select" aria-label="Default select example"
                                                                {...register("course")} disabled>
                                                                <option value="">Choose Course</option>
                                                                {course?.map((elem) => {
                                                                    return (<option value={elem.id}>{elem.name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="mb-3">
                                                            <label for="exampleFormControlInput1" className="form-label">Branch</label>
                                                            <div className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M9.15722 20.7714V17.7047C9.1572 16.9246 9.79312 16.2908 10.581 16.2856H13.4671C14.2587 16.2856 14.9005 16.9209 14.9005 17.7047V17.7047V20.7809C14.9003 21.4432 15.4343 21.9845 16.103 22H18.0271C19.9451 22 21.5 20.4607 21.5 18.5618V18.5618V9.83784C21.4898 9.09083 21.1355 8.38935 20.538 7.93303L13.9577 2.6853C12.8049 1.77157 11.1662 1.77157 10.0134 2.6853L3.46203 7.94256C2.86226 8.39702 2.50739 9.09967 2.5 9.84736V18.5618C2.5 20.4607 4.05488 22 5.97291 22H7.89696C8.58235 22 9.13797 21.4499 9.13797 20.7714V20.7714"
                                                                        stroke="#2D2926"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <select className="form-select" aria-label="Default select example"
                                                                {...register("branch")} disabled>
                                                                <option value=''>branch</option>
                                                                {branch && branch.map((elem) => {
                                                                    return (
                                                                        <option value={elem.id}>{elem.branch_name}</option>)
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="profile_add">
                                    <h2>Your Address & Contact Details</h2>
                                </div>
                                <hr className="m-0" />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="checkout_field contact_field">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Post Code<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Post Code"
                                                            {...register("post_code")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Address<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Address"
                                                            {...register("address")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">City/Suburb<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="City"
                                                            {...register("city")} />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">State<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="State"
                                                            {...register("state")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Country</label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Country"
                                                            {...register("country")} />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-3">
                                                    <div className="mb-3 info_field">
                                                        <label for="exampleFormControlInput1" className="form-label">Country<span className="star_symb">*</span></label>
                                                        <select className="form-select" name="country"
                                                            {...register("country")}
                                                            onChange={(e) => setCountryId(e.target.value)}
                                                        >
                                                            <option >Country</option>
                                                            {country && country?.map((elem) => {
                                                                return (
                                                                    <option value={elem.id}>{elem.name}</option>)
                                                            })}
                                                        </select>
                                                        <div className="work_icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="mb-3 info_field">
                                                        <label for="exampleFormControlInput1" className="form-label">State<span className="star_symb">*</span></label>
                                                        <select className="form-select" name="state" {...register("state")}
                                                        >
                                                            <option value="">State</option>
                                                            {state && state?.map((elem) => {
                                                                return (
                                                                    <>
                                                                        <option value={elem.id}> {elem.name}</option>
                                                                        <option value='1'>australia1</option>
                                                                        <option value='2'>australia2</option>
                                                                        <option value='3'>australia3</option>
                                                                        <option value='4'>australia4</option>
                                                                    </>

                                                                )
                                                            })}
                                                        </select>
                                                        <div className="work_icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M14.5 10.5005C14.5 9.11924 13.3808 8 12.0005 8C10.6192 8 9.5 9.11924 9.5 10.5005C9.5 11.8808 10.6192 13 12.0005 13C13.3808 13 14.5 11.8808 14.5 10.5005Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9995 21C10.801 21 4.5 15.8984 4.5 10.5633C4.5 6.38664 7.8571 3 11.9995 3C16.1419 3 19.5 6.38664 19.5 10.5633C19.5 15.8984 13.198 21 11.9995 21Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Telephone</label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1"
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            placeholder="Telephone No."
                                                            {...register("student_number")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Email</label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.9024 8.8512L13.4591 12.4642C12.6196 13.1302 11.4384 13.1302 10.5989 12.4642L6.11816 8.8512"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Email Address"
                                                            {...register("email")} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="profile_add">
                                    <h2>Parent's Personal Details</h2>
                                </div>
                                <hr className="m-0" />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="checkout_field parent_field">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Parent's First Name<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.42857"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="First Name"
                                                            {...register("p_first_name")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Parent's Last Name<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.42857"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Last Name"
                                                            {...register("p_last_name")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Parent’s Mobile Phone<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }} className="form-control" id="exampleFormControlInput1" placeholder="Mobile No."
                                                            {...register("father_mobilenumber")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Parent’s Email<span className="star_symb">*</span></label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.9024 8.8512L13.4591 12.4642C12.6196 13.1302 11.4384 13.1302 10.5989 12.4642L6.11816 8.8512"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Email"
                                                            {...register("p_email")} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <hr className="m-0" />
                                <div className="profile_add">
                                    <h2>Choose Username & password</h2>
                                </div>
                                <hr className="m-0" />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="checkout_field parent_field">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">User Name(Id)</label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.42857"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="User ID"
                                                            {...register("email")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Password</label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16.4228 9.4478V7.3008C16.4228 4.7878 14.3848 2.7498 11.8718 2.7498C9.35876 2.7388 7.31276 4.7668 7.30176 7.2808V7.3008V9.4478"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M15.683 21.2496H8.042C5.948 21.2496 4.25 19.5526 4.25 17.4576V13.1686C4.25 11.0736 5.948 9.37659 8.042 9.37659H15.683C17.777 9.37659 19.475 11.0736 19.475 13.1686V17.4576C19.475 19.5526 17.777 21.2496 15.683 21.2496Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path d="M11.8623 14.2028V16.4238" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Password"
                                                            {...register("password")} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-3">
                                                    <div className="mb-3">
                                                        <label for="exampleFormControlInput1" className="form-label">Confirm Password</label>
                                                        <div className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16.4228 9.4478V7.3008C16.4228 4.7878 14.3848 2.7498 11.8718 2.7498C9.35876 2.7388 7.31276 4.7668 7.30176 7.2808V7.3008V9.4478"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M15.683 21.2496H8.042C5.948 21.2496 4.25 19.5526 4.25 17.4576V13.1686C4.25 11.0736 5.948 9.37659 8.042 9.37659H15.683C17.777 9.37659 19.475 11.0736 19.475 13.1686V17.4576C19.475 19.5526 17.777 21.2496 15.683 21.2496Z"
                                                                    stroke="#2D2926"
                                                                    stroke-width="1.5"
                                                                    stroke-linecap="round"
                                                                    stroke-linejoin="round"
                                                                />
                                                                <path d="M11.8623 14.2028V16.4238" stroke="#2D2926" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <input type="password" className="form-control" id="exampleFormControlInput1" placeholder="Confirm Password" />
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div> */}
                                <div className="edit_profile new" type="submit">
                                    <button type='submit'>Update Profile</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfile;
