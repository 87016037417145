import React from 'react';
import { useState, useEffect } from 'react';
import { HomeWork_list } from '../../../apis/My-e-Test/HomeWork';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Pagination from '../../Pagination';

const Homework = () => {
    let itemsPerPage = 5;
    const [workList, setWorkList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(workList?.length / itemsPerPage);
    const studentType = localStorage.getItem('studentType')
    useEffect(() => {
        async function get_data() {
            const worklist = await HomeWork_list(currentPage);
            setWorkList(worklist?.data);
        }
        get_data();
    }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = workList?.slice(startIndex, endIndex);


    let generatePDF = () => {
        const doc = new jsPDF();
        if (workList) {
            const tableData = workList.map((record) => {
                // Flatten the cardDetails array and combine it with other fields
                const flattenedRecord = {
                    ...record
                };
                return Object.values(flattenedRecord);
            });

            const tableHeaders = Object.keys(workList[0]);

            doc.autoTable({
                columnStyles: {
                    6: { cellWidth: 25 },
                    7: { cellWidth: 30 },
                },
                head: [tableHeaders],
                body: tableData,
            });

            // doc.save('Userslist.pdf');
            doc.output('dataurlnewwindow');
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="table_head">
                    <h2 className="">Homework List</h2>
                </div>
                <div className="table-responsive tbl_bg">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Subject</th>
                                <th scope="col">Time</th>
                                <th scope="col">Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentData && currentData?.map((elem) => {
                                    const dateTimeComponents = elem?.date?.split(' ');
                                    const dateComponents = dateTimeComponents[0]?.split('-');
                                    const timeComponents = dateTimeComponents[1]?.split(':');
                                    const year = parseInt(dateComponents[0]);
                                    const month = parseInt(dateComponents[1]) - 1; // Months are zero-indexed
                                    const day = parseInt(dateComponents[2]);
                                    const hours = parseInt(timeComponents[0]);
                                    const minutes = parseInt(timeComponents[1]);
                                    const seconds = parseInt(timeComponents[2]);
                                    const formattedDate = new Date(year, month, day, hours, minutes, seconds).toISOString().substring(0, 10);
                                    
                                    return (
                                        <tr>
                                            <td scope="row">{elem?.subject}</td>
                                            <td>{`${elem?.time}minute`}</td>
                                            <td>{`${formattedDate}`}</td>
                                            <td>
                                                {studentType==='1'?
                                                <button onClick={generatePDF}>
                                                View
                                            </button>:'Enroll Now'}
                                                </td>
                                            
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div className="pagin">
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                        />
                    </div>
                </div>
                <div className="tbl_content">
                    <ol type="1">
                        <li>* If you are not enrolled in CS Online School, please <span className="join">Join Membership first</span> and then take the test.</li>
                        <li>You can take the test ONLY ONCE during the available period.</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default Homework