import React from 'react';
import "../../App.css"
export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <div>

            <div className="pagin">
                <div >
                    <a className={currentPage === 1 ? 'not-click listleft_arrow' : 'listleft_arrow'} onClick={() => onPageChange(currentPage - 1)} href="#!">
                        <svg width="7" height="13" viewBox="0 0 7 13" fill={currentPage === 1 ? 'black' : 'red'} xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.402119 5.87884L5.35462 0.935093C5.43596 0.853081 5.53274 0.787986 5.63936 0.743563C5.74599 0.699141 5.86036 0.67627 5.97587 0.67627C6.09138 0.67627 6.20575 0.699141 6.31237 0.743563C6.419 0.787986 6.51578 0.853081 6.59712 0.935093C6.76009 1.09904 6.85156 1.32081 6.85156 1.55197C6.85156 1.78313 6.76009 2.0049 6.59712 2.16884L2.26587 6.54384L6.59712 10.8751C6.76009 11.039 6.85156 11.2608 6.85156 11.492C6.85156 11.7231 6.76009 11.9449 6.59712 12.1088C6.51608 12.1915 6.41944 12.2573 6.3128 12.3024C6.20616 12.3474 6.09164 12.3709 5.97587 12.3713C5.8601 12.3709 5.74558 12.3474 5.63894 12.3024C5.5323 12.2573 5.43566 12.1915 5.35462 12.1088L0.402119 7.16509C0.313302 7.08316 0.242419 6.98371 0.193938 6.87302C0.145457 6.76234 0.120428 6.64281 0.120428 6.52197C0.120428 6.40113 0.145457 6.2816 0.193938 6.17091C0.242419 6.06023 0.313302 5.96078 0.402119 5.87884Z" />
                        </svg>
                    </a>
                </div>
                {pageNumbers.map((pageNumber) => (
                    <div key={pageNumber} className={pageNumber === currentPage ? 'active' : ''}>
                        <a className="first" onClick={() => onPageChange(pageNumber)} href="#!" style={{ color: pageNumber === currentPage ? 'red' : ' black' }}>
                            {pageNumber}
                        </a>
                    </div>
                ))}
                <div>
                    <a className={currentPage === totalPages ? 'not-click listright_arrow' : ' listright_arrow'} onClick={() => onPageChange(currentPage + 1)} href="#!">
                        <svg width="7" height="13" viewBox="0 0 7 13" fill={currentPage === totalPages ? 'black' : 'red'} xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.59788 5.87884L1.64538 0.935093C1.56404 0.853081 1.46726 0.787986 1.36064 0.743563C1.25401 0.699141 1.13964 0.67627 1.02413 0.67627C0.908621 0.67627 0.794253 0.699141 0.687626 0.743563C0.580999 0.787986 0.484224 0.853081 0.402881 0.935093C0.239911 1.09904 0.148438 1.32081 0.148438 1.55197C0.148438 1.78313 0.239911 2.0049 0.402881 2.16884L4.73413 6.54384L0.402881 10.8751C0.239911 11.039 0.148438 11.2608 0.148438 11.492C0.148438 11.7231 0.239911 11.9449 0.402881 12.1088C0.483919 12.1915 0.580558 12.2573 0.687199 12.3024C0.79384 12.3474 0.908362 12.3709 1.02413 12.3713C1.1399 12.3709 1.25442 12.3474 1.36106 12.3024C1.4677 12.2573 1.56434 12.1915 1.64538 12.1088L6.59788 7.16509C6.6867 7.08316 6.75758 6.98371 6.80606 6.87302C6.85454 6.76234 6.87957 6.64281 6.87957 6.52197C6.87957 6.40113 6.85454 6.2816 6.80606 6.17091C6.75758 6.06023 6.6867 5.96078 6.59788 5.87884Z" />
                            </svg>
                        </a>
                </div>
            </div>
        </div>
    );
};







