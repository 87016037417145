import { HTTPURL } from '../constants/Matcher';

export async function get_grade() {
    try {
        const response = await fetch(HTTPURL + 'api/grades-all');
        const responseData = await response.json();
        if (responseData.status) {
            // responseData?.message;
        }
        // else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_course() {
    try {
        const response = await fetch(HTTPURL + 'api/courses-all');
        const responseData = await response.json();
        // if (responseData.status) {
        //     responseData?.message;
        // } else {
        //    responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


export async function get_branch() {
    try {
        const response = await fetch(HTTPURL + 'api/branches-all');
        const responseData = await response.json();
        // if (responseData.status) {
        //   responseData?.message;
        // } else {
        //     responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

export async function get_term() {
    try {
        const response = await fetch(HTTPURL + 'api/all-terms-data');
        const responseData = await response.json();
        // if (responseData.status) {
        //   responseData?.message;
        // } else {
        //     responseData?.message;
        // }
        console.log('countery',responseData.data[0].id);
        return responseData;
    
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




export async function get_country() {
    try {
        const response = await fetch(HTTPURL + 'api/countries-all');
        const responseData = await response.json();
        // if (responseData.status) {
        //   responseData?.message;
        // } else {
        //     responseData?.message;
        // }
        console.log('countery',responseData.data[0].id);
        return responseData;
    
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}



export async function get_state(countryId) {
    try {
        const response = await fetch(HTTPURL + `api/states-all?id=${countryId}`);
        const responseData = await response.json();
        // if (responseData.status) {
        //   responseData?.message;
        // } else {
        //     responseData?.message;
        // }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all subjects 
export async function subject_all()
{
    try {
        const response = await fetch(HTTPURL + 'api/subject-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get subject by grade id 
export async function subject_by_grade_id(id)
{
    try {
        const response = await fetch(HTTPURL + `api/get-subject-by-grade/?id=${id}`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get insert id
export async function insertId() {
    try {
        const response = await fetch(HTTPURL + `api/get-last-insert-id`);
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}